import React, { useState } from 'react';
import axios from 'axios';
import '../assets/Luft.css'; // Assuming you have a CSS file for Luft module styling
import Header from '../assets/Header.js';  // Import the Header component

const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

function LuftModule() {
  const [temperature, setTemperature] = useState('');
  const [pressure, setPressure] = useState('');
  const [interpolatedData, setInterpolatedData] = useState(null);
  const [error, setError] = useState(null);

  // Handle form submission
  const handleSubmit = () => {
    if (!temperature || !pressure) {
      setError('Please fill out both fields.');
      return;
    }

    // Reset error and send request to the API
    setError(null);
    axios.post(`${apiBaseUrl}/api/luft/get_properties`, {
      pressure: pressure,
      temperature: temperature
    })
    .then(response => {
      setInterpolatedData(response.data);
      setError(null);
    })
    .catch(error => {
      console.error('Error fetching properties:', error);
      setError('Failed to fetch interpolated data. Please try again.');
    });
  };

  // Create an array of properties with name, unit, and value
  const formattedData = interpolatedData ? [
    { name: 'Pressure', unit: 'bara', value: interpolatedData.pressure },
    { name: 'Temperature', unit: '°C', value: interpolatedData.temperature },
    { name: 'Density', unit: 'kg/m³', value: interpolatedData.density },
    { name: 'Viscosity', unit: 'mPa·s', value: interpolatedData.viscosity },
    { name: 'Conductivity', unit: 'mW/m·K', value: interpolatedData.conductivity },
    { name: 'Specific Gravity', unit: 'kJ/kg·K', value: interpolatedData.gravity }
  ] : [];

  return (
    <div className="container">
      {/* Use the shared Header component */}
      <Header title="Air Properties Module" />

      {/* Form Fields */}
      <div className="form-group">
        <label>Pressure (bara):</label>
        <input
          type="number"
          className="form-control"
          value={pressure}
          onChange={e => setPressure(e.target.value)}
        />
      </div>

      <div className="form-group">
        <label>Temperature (°C):</label>
        <input
          type="number"
          className="form-control"
          value={temperature}
          onChange={e => setTemperature(e.target.value)}
        />
      </div>

      <button onClick={handleSubmit} className="btn btn-primary">Get Interpolated Data</button>

      {/* Display error message if any */}
      {error && <div className="alert alert-danger" role="alert">{error}</div>}

      {/* Display interpolated data in a table format */}
      <div id="results" className="mt-4">
        {formattedData.length > 0 ? (
          <table className="table table-bordered mt-4">
            <thead>
              <tr>
                <th>Name</th>
                <th>Unit</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {formattedData.map((property, index) => (
                <tr key={index}>
                  <td>{property.name}</td>
                  <td>{property.unit}</td>
                  <td>{typeof property.value === 'number' ? property.value.toFixed(2) : property.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No data available. Please fill out the form and submit.</p>
        )}
      </div>
    </div>
  );
}

export default LuftModule;
