// src/UniqueIDContext.js
import React, { createContext, useState } from 'react';

export const UniqueIDContext = createContext();

export const UniqueIDProvider = ({ children }) => {
  const [uniqueID, setUniqueID] = useState(null);

  return (
    <UniqueIDContext.Provider value={{ uniqueID, setUniqueID }}>
      {children}
    </UniqueIDContext.Provider>
  );
};