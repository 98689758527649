import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Menu, MenuItem, IconButton, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';

function IterMenu({
  onCalculate,
  onNewCalculation,
  onSaveCalculation,
  onLoadCalculation,
  onImportCalculation,
  onExportCalculation,
  onExportToPDF,
  onExit,
  onResetForm,
  onUndo,
  onRedo,
  onToggleUnits,
  onShowHistory,
  onToggleLogs,
  onToggleAddEquation,
  onOpenMaterialDatabase,
  onOpenUnitConverter,
  onOpenGeometryHelper,
  onOpenEquationEditor,
  onOpenUserGuide,
  onOpenAbout,
  onContactSupport,
  onCheckForUpdates,
  onToggleDarkMode,
  isDarkMode,
  showAddEquation  // Add this prop
}) {
  const [fileMenuAnchorEl, setFileMenuAnchorEl] = useState(null);
  const [editMenuAnchorEl, setEditMenuAnchorEl] = useState(null);
  const [viewMenuAnchorEl, setViewMenuAnchorEl] = useState(null);
  const [toolsMenuAnchorEl, setToolsMenuAnchorEl] = useState(null);
  const [helpMenuAnchorEl, setHelpMenuAnchorEl] = useState(null);

  const handleMenuClick = (event, setAnchorEl) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (setAnchorEl) => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton>

        {/* File Menu */}
        <Button
          color="inherit"
          onClick={(e) => handleMenuClick(e, setFileMenuAnchorEl)}
        >
          File
        </Button>
        <Menu
          anchorEl={fileMenuAnchorEl}
          open={Boolean(fileMenuAnchorEl)}
          onClose={() => handleMenuClose(setFileMenuAnchorEl)}
        >
          <MenuItem onClick={onNewCalculation}>New Calculation</MenuItem>
          <MenuItem onClick={onSaveCalculation}>Save Calculation</MenuItem>
          <MenuItem onClick={onLoadCalculation}>Load Calculation</MenuItem>
          <MenuItem onClick={onImportCalculation}>Import Calculation</MenuItem>
          <MenuItem onClick={onExportCalculation}>Export Calculation</MenuItem>
          <MenuItem onClick={onExportToPDF}>Export to PDF</MenuItem>
          <MenuItem onClick={onExit}>Exit</MenuItem>
        </Menu>

        {/* Add "Solve Equations" Button */}
        <Button color="inherit" onClick={onCalculate}>Solve Equations</Button>

        {/* Edit Menu */}
        <Button
          color="inherit"
          onClick={(e) => handleMenuClick(e, setEditMenuAnchorEl)}
        >
          Edit
        </Button>
        <Menu
          anchorEl={editMenuAnchorEl}
          open={Boolean(editMenuAnchorEl)}
          onClose={() => handleMenuClose(setEditMenuAnchorEl)}
        >
          <MenuItem onClick={onResetForm}>Reset Form</MenuItem>
          <MenuItem onClick={onUndo}>Undo</MenuItem>
          <MenuItem onClick={onRedo}>Redo</MenuItem>
        </Menu>

        {/* View Menu */}
        <Button
          color="inherit"
          onClick={(e) => handleMenuClick(e, setViewMenuAnchorEl)}
        >
          View
        </Button>
        <Menu
          anchorEl={viewMenuAnchorEl}
          open={Boolean(viewMenuAnchorEl)}
          onClose={() => handleMenuClose(setViewMenuAnchorEl)}
        >
          <MenuItem onClick={onToggleUnits}>Toggle Units</MenuItem>
          <MenuItem onClick={onShowHistory}>Calculation History</MenuItem>
          <MenuItem onClick={onToggleLogs}>Show/Hide Logs</MenuItem>
          <MenuItem onClick={onToggleAddEquation}>{showAddEquation ? 'Hide Add New Equation' : 'Show Add New Equation'} {/* Dynamic label */}</MenuItem>
          <MenuItem onClick={onToggleDarkMode}>{isDarkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'}</MenuItem>
        </Menu>

        {/* Tools Menu */}
        <Button
          color="inherit"
          onClick={(e) => handleMenuClick(e, setToolsMenuAnchorEl)}
        >
          Tools
        </Button>
        <Menu
          anchorEl={toolsMenuAnchorEl}
          open={Boolean(toolsMenuAnchorEl)}
          onClose={() => handleMenuClose(setToolsMenuAnchorEl)}
        >
          <MenuItem onClick={onOpenMaterialDatabase}>Material Database</MenuItem>
          <MenuItem onClick={onOpenUnitConverter}>Unit Converter</MenuItem>
          <MenuItem onClick={onOpenGeometryHelper}>Geometry Helper</MenuItem>
          <MenuItem onClick={onOpenEquationEditor}>Equation Editor</MenuItem>
        </Menu>

        {/* Help Menu */}
        <Button
          color="inherit"
          onClick={(e) => handleMenuClick(e, setHelpMenuAnchorEl)}
        >
          Help
        </Button>
        <Menu
          anchorEl={helpMenuAnchorEl}
          open={Boolean(helpMenuAnchorEl)}
          onClose={() => handleMenuClose(setHelpMenuAnchorEl)}
        >
          <MenuItem onClick={onOpenUserGuide}>User Guide</MenuItem>
          <MenuItem onClick={onOpenAbout}>About</MenuItem>
          <MenuItem onClick={onContactSupport}>Contact Support</MenuItem>
          <MenuItem onClick={onCheckForUpdates}>Check for Updates</MenuItem>
        </Menu>

        {/* Branding */}
        <Typography variant="h6" style={{ flexGrow: 1, textAlign: 'right' }}>
          LV-SOFT.NET
        </Typography>

        <IconButton edge="end" color="inherit">
          <MoreVertIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

export default IterMenu;
