import React, { useState } from 'react';
import axios from 'axios';
import '../assets/Pden.css'; // Assuming you have a CSS file for PED module styling
import Header from '../assets/Header.js';  // Import the Header component

const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

function PdenModule() {
  const [equipmentType, setEquipmentType] = useState('');
  const [mediumState, setMediumState] = useState('');
  const [mediumGroup, setMediumGroup] = useState('');
  const [pressure, setPressure] = useState('');
  const [volume, setVolume] = useState('');
  const [diameter, setDiameter] = useState('');
  const [category, setCategory] = useState('');
  const [conformityProcedure, setConformityProcedure] = useState('');
  const [modules, setModules] = useState([]);  // Add state to hold modules
  const [diagram, setDiagram] = useState(null);
  const [error, setError] = useState(null);

  // Handle form submission
  const handleSubmit = () => {
    if (!pressure || !volume || !equipmentType || !mediumState || !mediumGroup) {
      setError('Please fill out all required fields.');
      return;
    }

    // Reset error and send request to the API
    setError(null);

    console.log("Sending data to /classify:", {
      equipment_type: equipmentType,
      medium_state: mediumState,
      pressure: pressure,
      volume: volume,
      diameter: diameter,
      medium_group: mediumGroup
    }); 

      // Erster API-Aufruf für die Klassifizierung
      axios.post(`${apiBaseUrl}/api/pden/classify`, {
        equipment_type: equipmentType,
        medium_state: mediumState,
        pressure: pressure,
        volume: volume,
        diameter: diameter,
        medium_group: mediumGroup
      })
      .then(response => {
        const data = response.data;
        setCategory(data.category);
        setConformityProcedure(data.procedure);
        setModules(data.modules);  // Set the modules based on response
        setError(null);

        // Nach der Klassifizierung, fordere das Diagramm an

      axios.post(`${apiBaseUrl}/api/pden/diagram`, {
        pressure: pressure,
        volume: volume,
        equipment_type: equipmentType,
        medium_state: mediumState,
        medium_group: mediumGroup
      }, {
        responseType: 'blob'  // Bild im Blob-Format erwarten
      })
      .then(diagramResponse => {
        const imageUrl = URL.createObjectURL(diagramResponse.data);  // URL für das Bild erstellen
        setDiagram(imageUrl);  // Setze die Bild-URL in den Zustand
        setError(null);
      })
      .catch(diagramError => {
        console.error('Error fetching diagram:', diagramError);
        setError('Failed to fetch PED classification diagram. Please try again.');
      });
    })
    .catch(error => {
      console.error('Error fetching classification:', error);
      setError('Failed to fetch PED classification data. Please try again.');
    });
  };

  return (
    <div className="container">
      {/* Verwende die Header-Komponente */}
      <Header title="PED Classification Module" />

      {/* Formularfelder */}
      <div className="form-group">
        <label>Equipment Type (Article 1/2):</label>
        <select
          className="form-control"
          value={equipmentType}
          onChange={e => {
            setEquipmentType(e.target.value);
            if (e.target.value !== "Piping") {
              setDiameter(''); // Durchmesser löschen, wenn nicht Piping
            }
          }}
        >
          <option value="">Select Equipment Type</option>
          <option value="Vessel">Vessel</option>
          <option value="Piping">Piping</option>
          <option value="Pressure-bearing parts">Pressure-bearing parts</option>
          <option value="Steam/Hot water generators">Steam/Hot water generators</option>
        </select>
      </div>

      {/* Bedingte Anzeige des Durchmessers nur für Piping */}
      {equipmentType === "Piping" && (
        <div className="form-group">
          <label>Diameter DN (only for piping):</label>
          <input
            type="number"
            className="form-control"
            value={diameter}
            onChange={e => setDiameter(e.target.value)}
          />
        </div>
      )}

      <div className="form-group">
        <label>Medium State (Article 4):</label>
        <select className="form-control" value={mediumState} onChange={e => setMediumState(e.target.value)}>
          <option value="">Select Medium State</option>
          <option value="gaseous">Gaseous or Liquid (pD &gt; 0.5 bar)</option>
          <option value="liquid_low">Liquid (pD ≤ 0.5 bar)</option>
        </select>
      </div>

      <div className="form-group">
        <label>Maximum Allowable Pressure PS (bar):</label>
        <input
          type="number"
          className="form-control"
          value={pressure}
          onChange={e => setPressure(e.target.value)}
        />
      </div>

      <div className="form-group">
        <label>Volume (L):</label>
        <input
          type="number"
          className="form-control"
          value={volume}
          onChange={e => setVolume(e.target.value)}
        />
      </div>

      <div className="form-group">
        <label>Medium Group (Article 13):</label>
        <select className="form-control" value={mediumGroup} onChange={e => setMediumGroup(e.target.value)}>
          <option value="">Select Medium Group</option>
          <option value="Group 1 - dangerous">Group 1 - dangerous</option>
          <option value="Group 2 - all others">Group 2 - all others</option>
        </select>
      </div>

      <button onClick={handleSubmit} className="btn btn-primary">Get PED Classification</button>

      {/* Abstände zwischen Button und Klassifikationsergebnissen */}
      <div style={{ marginTop: '30px' }}>
        {/* Fehleranzeige */}
        {error && <div className="alert alert-danger" role="alert">{error}</div>}

        {/* Anzeige der Klassifizierung, Konformitätsverfahren und Module */}
        {category && (
          <div className="classification-results">
            <p><strong>Classification Category:</strong> {category}</p>
            <p><strong>Conformity Procedure:</strong> {conformityProcedure}</p>

            {/* Anzeige der verfügbaren Module */}
            <h4>Available Modules:</h4>
            <ul>
              {modules.length > 0 ? (
                modules.map((module, index) => (
                  <li key={index}>{module}</li>
                ))
              ) : (
                <p>No modules available for this category.</p>
              )}
            </ul>
          </div>
        )}
      </div>

      {/* Anzeige des Klassifikationsdiagramms */}
      <div id="results" className="mt-4">
        {diagram ? (
          <div>
            <p>PED Classification Diagram:</p>
            <img src={diagram} alt="PED Classification Diagram" style={{ maxWidth: '100%' }} />
          </div>
        ) : (
          <p>No diagram available. Please fill out the form and submit.</p>
        )}
      </div>
    </div>
  );
}

export default PdenModule;
