import React, { useState } from 'react';
import axios from 'axios';
import '../assets/Create.css';  // Ensure that this path points to your CreateModule CSS

const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const CreateModule = () => {
  const [description, setDescription] = useState('');  // Description of the calculation
  const [generatedJson, setGeneratedJson] = useState('');  // JSON generated by ChatGPT
  const [manualJsonInput, setManualJsonInput] = useState('');  // Manually input JSON
  const [moduleName, setModuleName] = useState('');
  const [equations, setEquations] = useState([{ value: '' }]);  // Editable equations
  const [variables, setVariables] = useState([{ name: '', unit: '', condition: '', inputType: '' }]);
  const [responseMessage, setResponseMessage] = useState('');

  // Handle user description for calculation and send it to ChatGPT
  const handleGenerateModule = async () => {
    try {
      const response = await axios.post(`${apiBaseUrl}/api/create/generate-module`, {
        description,  // Send only the description to the backend
      });

      const generatedJson = response.data.generated_json;
      setGeneratedJson(generatedJson);  // Show the generated JSON for user review
      setResponseMessage("Module JSON generated successfully! Please review or modify the JSON.");
    } catch (error) {
      setResponseMessage('Error generating the module');
    }
  };

  // Load the generated JSON into the form fields
  const handleLoadGeneratedJson = () => {
    try {
      const parsedData = JSON.parse(generatedJson);

      // Populate the fields with the parsed JSON
      setModuleName(parsedData.module_name || '');
      // Parse the equations and variables directly from the JSON object
      setEquations((parsedData.equations || []).map(eq => ({ value: eq })));
      setVariables(parsedData.variables || []);

      setResponseMessage("Generated JSON successfully loaded into the form.");
    } catch (err) {
      setResponseMessage("Invalid JSON format.");
    }
  };

  // Handle manual JSON input
  const handleManualJsonInput = (event) => {
    setManualJsonInput(event.target.value);
  };

  // Load the manually input JSON into the form fields
  const handleManualJsonSubmit = () => {
    try {
      const parsedData = JSON.parse(manualJsonInput);

      // Populate the fields with the parsed JSON
      setModuleName(parsedData.module_name || '');
      // Parse the equations and variables directly from the JSON object
      setEquations((parsedData.equations || []).map(eq => ({ value: eq })));
      setVariables(parsedData.variables || []);

      setResponseMessage("Manually input JSON successfully loaded into the form.");
    } catch (err) {
      setResponseMessage("Invalid JSON format.");
    }
  };

  // Handle equation change
  const handleEquationChange = (index, event) => {
    const newEquations = equations.map((eq, eqIndex) => {
      if (index === eqIndex) {
        return { value: event.target.value };
      }
      return eq;
    });
    setEquations(newEquations);
  };

  // Handle adding a new equation
  const handleAddEquation = () => {
    setEquations([...equations, { value: '' }]);
  };

  // Handle variable change
  const handleVariableChange = (index, event) => {
    const { name, value } = event.target;
    const newVariables = variables.map((variable, varIndex) => {
      if (index === varIndex) {
        return { ...variable, [name]: value };
      }
      return variable;
    });
    setVariables(newVariables);
  };

  // Handle adding a new variable
  const handleAddVariable = () => {
    setVariables([...variables, { name: '', unit: '', condition: '', inputType: '' }]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      moduleName,
      equations: equations.map(eq => eq.value),
      variables
    };

    try {
      const response = await axios.post(`${apiBaseUrl}/api/create/create-module`, data);
      setResponseMessage(response.data.message || "Module created successfully!");
    } catch (error) {
      setResponseMessage('Error creating the module');
    }
  };

  return (
    <div className="create-module-container">
      <h1 className="create-header">Create a New Module</h1>

      {/* Description for the calculation */}
      <div className="form-section">
        <h4>Describe the Calculation</h4>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          rows="5"
          style={{ width: "100%" }}
          placeholder="Describe the type of calculation needed..."
        />
        <button onClick={handleGenerateModule} className="btn btn-secondary mt-2">
          Generate Module JSON
        </button>
      </div>

      {/* Display generated JSON */}
      {generatedJson && (
        <div className="form-section">
          <h4>Generated JSON (You can modify this if needed)</h4>
          <textarea
            value={generatedJson}
            onChange={(e) => setGeneratedJson(e.target.value)}  // Allow editing of generated JSON
            rows="10"
            style={{ width: "100%" }}
          />
          <button onClick={handleLoadGeneratedJson} className="btn btn-secondary mt-2">
            Load Generated JSON into Form
          </button>
        </div>
      )}

      {/* Manual JSON Input Section */}
      <div className="form-section">
        <h4>Paste JSON to Load Data</h4>
        <textarea
          value={manualJsonInput}
          onChange={handleManualJsonInput}
          rows="10"
          style={{ width: "100%" }}
          placeholder="Paste your JSON here..."
        />
        <button onClick={handleManualJsonSubmit} className="btn btn-secondary mt-2">
          Load Pasted JSON into Form
        </button>
      </div>

      {/* Form Fields */}
      <form onSubmit={handleSubmit}>
        <div className="form-section">
          <label>
            Module Name:
            <input
              type="text"
              value={moduleName}
              onChange={(e) => setModuleName(e.target.value)}
              required
            />
          </label>
        </div>

        <div className="equations-section">
          <h3>Equations</h3>
          {equations.map((equation, index) => (
            <div className="equation-row" key={index}>
              <label>
                Equation {index + 1}:
                <input
                  type="text"
                  value={equation.value}
                  onChange={(e) => handleEquationChange(index, e)}  // User can edit equations directly
                  required
                />
              </label>
            </div>
          ))}
          <button type="button" className="btn-add" onClick={handleAddEquation}>Add Equation</button>
        </div>

        <div className="variables-section">
          <h3>Variables</h3>
          {variables.map((variable, index) => (
            <div className="variable-row" key={index}>
              <label>
                Name:
                <input
                  type="text"
                  name="name"
                  value={variable.name}
                  onChange={(e) => handleVariableChange(index, e)}
                  required
                />
              </label>
              <label>
                Unit:
                <input
                  type="text"
                  name="unit"
                  value={variable.unit}
                  onChange={(e) => handleVariableChange(index, e)}
                  required
                />
              </label>
              <label>
                Condition:
                <input
                  type="text"
                  name="condition"
                  value={variable.condition}
                  onChange={(e) => handleVariableChange(index, e)}
                  required
                />
              </label>
              <label>
                Input Type:
                <select
                  name="inputType"
                  value={variable.inputType}
                  onChange={(e) => handleVariableChange(index, e)}
                  required
                >
                  <option value="standard">Standard</option>
                  <option value="readonly">Readonly</option>
                  <option value="combobox">Combobox</option>
                </select>
              </label>
            </div>
          ))}
          <button type="button" className="btn-add" onClick={handleAddVariable}>Add Variable</button>
        </div>

        <button type="submit" className="btn-submit">Create Module</button>
      </form>

      {responseMessage && (
        <p className={`response-message ${responseMessage.includes('successfully') ? 'success' : 'error'}`}>
          {responseMessage}
        </p>
      )}
    </div>
  );
};

export default CreateModule;
