import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../assets/Bier.css'; // Go one directory up from modules/ to find App.css in the src/ folder
import Header from '../assets/Header.js';  // Import the Header component
// import { useNavigate } from 'react-router-dom';  // Import useNavigate for routing
// import logo from '../assets/logo.png';  // Adjust the path as needed

// Use environment variable for API base URL, fallback to localhost for development
const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

function BierModule() {
  const [oils, setOils] = useState([]);
  const [selectedOil, setSelectedOil] = useState('');
  const [temperature, setTemperature] = useState('');
  const [property, setProperty] = useState('Dichte_kg_m2');
  const [properties, setProperties] = useState({});
  const [plotUrl, setPlotUrl] = useState('');
  const [cellStyle, setCellStyle] = useState({});
  const [contextMenu, setContextMenu] = useState(null);
  const [logs, setLogs] = useState([]);
  // const navigate = useNavigate();  // Initialize the navigate function

  // Fetch oils when the component mounts
  useEffect(() => {
    addLog('Fetching medium from backend...');
    axios.get(`${apiBaseUrl}/api/bier/beer_data`)
      .then(response => {
        if (response.data.length === 0) {
          addLog('No medium found.');
        } else {
          setOils(response.data);
          addLog(`Medium fetched successfully: ${response.data.join(', ')}`);
        }
      })
      .catch(error => {
        console.error('Error fetching medium:', error);
        addLog(`Error fetching medium: ${error.message}`);
      });
  }, []);

  useEffect(() => {
    const handleClickOutside = () => {
      if (contextMenu) {
        setContextMenu(null); // Schließt das Menü, wenn außerhalb geklickt wird
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [contextMenu]);

  const addLog = (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
  };

  // // Define the handleBackToMenu function
  // const handleBackToMenu = () => {
  //   navigate('/');  // Adjust the route as needed for your main menu
  // };

  const handleSubmit = () => {
    if (!selectedOil || !temperature) {
      addLog("Please select an medium and enter a valid temperature.");
      return;
    }

    addLog(`Fetching properties for ${selectedOil} at ${temperature}°C...`);

    axios.post(`${apiBaseUrl}/api/bier/get_beer_properties`, {
      medium: selectedOil,
      temperature: temperature,
      property: property
    })
    .then(response => {
      setProperties(response.data.properties || {});
      setPlotUrl(response.data.plot_url || '');
      addLog("Properties fetched successfully.");
      console.log("Properties data:", response.data.properties);
    })
    .catch(error => {
      console.error('Error fetching properties:', error);
      addLog(`Error fetching properties: ${error.message}`);
    });
  };

  // Navigate back to the main menu
  const handleRightClick = (e, key) => {
    e.preventDefault();
    setContextMenu({ x: e.pageX, y: e.pageY, key });
  };

  const saveAllVarsToDatabase = () => {
    console.log('Attempting to save all variables to the database...');
    const uniqueId = '4ec5c968-f769-488a-a1d5-5d92f3401105'; // Beispiel für die Unique ID

    if (!properties || properties.length === 0) {
      console.error('No properties to save.');
      return;
    }

    const variablesToSave = properties.map(prop => ({
      name: prop.name,
      unit: prop.unit,
      value: prop.value
    }));

    addLog('Saving all variables to the database...');

    axios
      .post(`${apiBaseUrl}/api/bier/save_beer_calculation`, {
        unique_id: uniqueId,
        variables: variablesToSave,
      })
      .then((response) => {
        console.log("Response from server:", response.data);
        addLog("All variables saved successfully.");
        setCellStyle(() => {
          const updatedStyle = {};
          properties.forEach((prop) => {
            updatedStyle[prop.name] = { color: "red", fontWeight: "bold" };
          });
          return updatedStyle;
        });
      })

      .catch((error) => {
        console.error("Error saving all variables:", error);
        addLog(`Error saving all variables: ${error.message}`);
      });
  };

  const deleteAllVarsFromDatabase = () => {
    console.log('Attempting to delete all variables from the database...');
    const uniqueId = '4ec5c968-f769-488a-a1d5-5d92f3401105'; // Beispiel für die Unique ID
    const moduleId = 1; // Beispiel für die aktuelle module_id
  
    addLog('Deleting all variables from the database...');
  
    axios.post(`${apiBaseUrl}/api/bier/delete_all_variables`, {
      unique_id: uniqueId,
      module_id: moduleId
    })
    .then(response => {
      console.log('Response from server:', response.data);
      addLog('All variables deleted successfully.');
      setProperties([]);  // Alle Eigenschaften leeren, nachdem sie gelöscht wurden
    })
    .catch(error => {
      console.error('Error deleting all variables:', error);
      addLog(`Error deleting all variables: ${error.message}`);
    });
  
    setContextMenu(null);  // Schließt das Kontextmenü nach der Aktion
  };
  

  // const saveVarToDatabase = (key) => {
  //   const property = properties.find(prop => prop.name === key);

  //   if (!property) {
  //     console.error(`Property with name ${key} not found.`);
  //     return;
  //   }

  //   const value = property.value;

  //   console.log("Saving key:", key, "with value:", value);

  //   if (value === undefined) return;

  //   setCellStyle(prevStyle => ({
  //     ...prevStyle,
  //     [key]: { color: 'red', fontWeight: 'bold' }
  //   }));

  //   setContextMenu(null);

  //   const userModuleId = 1; // Example module ID
  //   const variableId = 1;

  //   addLog(`Saving variable '${key}' with value '${value}' to the database...`);

  //   axios.post(`${apiBaseUrl}/api/bier/save_beer_variable`, {
  //     user_module_id: userModuleId,
  //     variable_id: variableId,
  //     user_value: value
  //   })
  //   .then(response => {
  //     addLog(`Variable '${key}' saved successfully.`);
  //   })
  //   .catch(error => {
  //     console.error('Error saving variable:', error);
  //     addLog(`Error saving variable '${key}': ${error.message}`);
  //   });
  // };

  return (
    <div className="container">
      {/* Use the shared Header component */}
      <Header title="Wort & Beer Properties Module" />

      {/* Form Fields */}
      <div className="form-group">
        <label htmlFor="oil">Please select a medium:</label>
        <select className="form-control" id="oil" value={selectedOil} onChange={e => setSelectedOil(e.target.value)}>
          <option value="">Select a medium</option>
          {oils.map(oil => (
            <option key={oil} value={oil}>{oil}</option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="temperature">Temperature (°C):</label>
        <input
          type="number"
          className="form-control"
          id="temperature"
          value={temperature}
          onChange={e => setTemperature(e.target.value)}
        />
      </div>

      <div className="form-group">
        <label htmlFor="property">Select property to plot:</label>
        <select className="form-control" id="property" value={property} onChange={e => setProperty(e.target.value)}>
          <option value="Dichte_kg_m2">Density (kg/m³)</option>
          <option value="DynVis_mPa_s">Dynamic Viscosity (mPa·s)</option>
          <option value="cp_kJ_kg_K">Specific Heat Capacity (kJ/(kg·K))</option>
          <option value="Lambda_W_m_K">Thermal Conductivity (W/(m·K))</option>
        </select>
      </div>

      <button onClick={handleSubmit} className="btn btn-primary">Get Properties</button>

      {/* Results */}
      <div id="results" className="mt-4">
        {properties && properties.length > 0 ? (
          <table className="table table-bordered mt-4">
            <thead>
              <tr>
                <th>Name</th>
                <th>Unit</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {properties.map((property, index) => (
                <tr key={index}>
                  <td>{property.name}</td>
                  <td>{property.unit}</td>
                  <td
                    style={cellStyle[property.name]}
                    onContextMenu={(e) => handleRightClick(e, property.name)}
                  >
                    {typeof property.value === 'number' ? property.value.toFixed(2) : property.value}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No properties available. Please select an medium and temperature.</p>
        )}
      </div>

      {/* Context Menu */}
        {contextMenu && (
        <div
            className="context-menu"
            style={{
            top: `${contextMenu.y}px`,
            left: `${contextMenu.x}px`,
            position: 'absolute',
            zIndex: 1000,
            }}
        >
            <button onClick={saveAllVarsToDatabase}>Save All Variables to Database</button>
            <button onClick={deleteAllVarsFromDatabase}>Delete All Variables from Database</button>  {/* Neuer Button zum Löschen aller Variablen */}
        </div>
        )}

      {/* Plot */}
      {plotUrl && (
        <div id="plot" className="mt-4 text-center">
          <img src={`data:image/png;base64,${plotUrl}`} alt="Plot" className="img-fluid" />
        </div>
      )}

      {/* Logs */}
      <div className="log-section mt-4">
        <h3>Logs:</h3>
        <ul>
          {logs.map((log, index) => (
            <li key={index}>{log}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default BierModule;
