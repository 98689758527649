import React, { useState } from 'react';
import axios from 'axios';

// Use environment variable for API base URL, fallback to localhost for development

const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

function X1092Module() {
  const [selectedStandard, setSelectedStandard] = useState('');
  const [flangeType, setFlangeType] = useState('');
  const [nominalDiameter, setNominalDiameter] = useState('');
  const [flangeData, setFlangeData] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = () => {
    const data = {
      standard: selectedStandard,
      flange_type: flangeType,
      nominal_diameter: parseInt(nominalDiameter),
    };

    axios.post(`${apiBaseUrl}/api/x1092_web/flange_dimensions`, data)
      .then(response => {
        setFlangeData(response.data);
        setError(null);
      })
      .catch(error => {
        setError("Error fetching flange dimensions. Please check your inputs.");
        console.error(error);
      });
  };

  return (
    <div className="container">
      <h1>Flange Dimensions Calculator</h1>

      <div className="form-group">
        <label>Select Standard</label>
        <select value={selectedStandard} onChange={e => setSelectedStandard(e.target.value)}>
          <option value="">Select a Standard</option>
          <option value="EN1092">EN 1092</option>
          <option value="ASME">ASME</option>
          <option value="B16">B16</option>
        </select>
      </div>

      <div className="form-group">
        <label>Flange Type</label>
        <input type="text" value={flangeType} onChange={e => setFlangeType(e.target.value)} placeholder="e.g. WeldNeck" />
      </div>

      <div className="form-group">
        <label>Nominal Diameter (DN)</label>
        <input type="number" value={nominalDiameter} onChange={e => setNominalDiameter(e.target.value)} placeholder="e.g. 100" />
      </div>

      <button onClick={handleSubmit}>Get Flange Dimensions</button>

      {flangeData && (
        <div>
          <h2>Flange Details</h2>
          <p>Standard: {flangeData.standard}</p>
          <p>Flange Type: {flangeData.flange_type}</p>
          <p>Nominal Diameter (DN): {flangeData['nominal_diameter (DN)']} mm</p>
          <p>Pressure Rating (PN): {flangeData['pressure_rating (PN)']} bar</p>
          <p>Bolt Circle Diameter: {flangeData.bolt_circle_diameter} mm</p>
          <p>Bolt Hole Diameter: {flangeData.bolt_hole_diameter} mm</p>
          <p>Bolt Count: {flangeData.bolt_count}</p>
          <p>Flange Thickness: {flangeData.flange_thickness} mm</p>
          <p>Outer Diameter: {flangeData.outer_diameter} mm</p>
          <p>Raised Face Diameter: {flangeData.raised_face_diameter} mm</p>
          <p>Hub Diameter: {flangeData.hub_diameter} mm</p>
          <p>Neck Diameter: {flangeData.neck_diameter} mm</p>
          <p>Neck Length: {flangeData.neck_length} mm</p>
          <p>Gasket Seating Area: {flangeData.gasket_seating_area} mm</p>
        </div>
      )}

      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
}

export default X1092Module;
