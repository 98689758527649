import React, { useState, useRef, useEffect } from "react";  // useRef - refresh button
import axios from "axios";
// import { useNavigate } from 'react-router-dom';  // Import useNavigate for routing
import Header from '../assets/Header.js';  // Import the Header component
import '../assets/Modulefinder.css'; // Go one directory up from modules/ to find App.css in the src/ folder
// import logo from '../assets/logo.png';  // Adjust the path as needed


// Verwende Umgebungsvariable für die API-Basis-URL, Fallback zu localhost für die Entwicklung
const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

  function ModuleFinder() {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [previewImages, setPreviewImages] = useState([]);
    const [results, setResults] = useState([]);
    const [logs, setLogs] = useState([]);
    const [uniqueID] = useState(sessionStorage.getItem("uniqueID"));
    // const navigate = useNavigate(); // Initialize the navigate function
    const fileInputRef = useRef(null); // Create a ref for the file input element

    const [textDescription, setTextDescription] = useState(""); // State for text input

    const [showRedirect, setShowRedirect] = useState(false); // New state to handle redirect message

    const [showModulesTable, setShowModulesTable] = useState(false); // State for showing the new modules table
    const [showModulesButton, setShowModulesButton] = useState(false); // State to control visibility of the "Modules you might need" button

    // const isMerged = (row) => row.category === row.equal_1 && row.category === row.equal_2;


    // Speech Recognition States
    const [isListening, setIsListening] = useState(false); // To toggle listening
    const [selectedLanguage, setSelectedLanguage] = useState("en-US"); // State for language selection
    const recognitionRef = useRef(null);
    const recognitionTimeout = useRef(null); // <-- Use useRef for timeout handling

    useEffect(() => {
      if (!("webkitSpeechRecognition" in window)) {
        alert("Speech recognition not supported in your browser.");
        return;
      }

      const recognition = new window.webkitSpeechRecognition();
      recognition.continuous = true;
      recognition.interimResults = true;
      recognition.lang = selectedLanguage;

      recognition.onresult = (event) => {
        let finalTranscript = "";

        for (let i = event.resultIndex; i < event.results.length; i++) {
          const result = event.results[i];
          if (result.isFinal) {
            finalTranscript += result[0].transcript;
          }
        }

        // Update textDescription directly
        setTextDescription((prev) => prev + finalTranscript);

        // Clear any previous timeout and restart listening timeout
        clearTimeout(recognitionTimeout.current); // Clear the previous timeout
        recognitionTimeout.current = setTimeout(() => {
          // Set new timeout
          recognition.stop();
          setIsListening(false);
        }, 2000); // 2-second timeout
      };

      recognition.onend = () => {
        if (isListening) {
          recognition.start(); // Automatically restart listening if still in "listening" mode
        }
      };

      recognitionRef.current = recognition;
    }, [isListening, selectedLanguage]);

    const handleToggleListening = (e) => {
      e.preventDefault();
      if (isListening) {
        // Stop listening immediately
        if (recognitionRef.current) {
          recognitionRef.current.abort(); // Abort immediately
          recognitionRef.current.stop(); // Ensure recognition is fully halted
        }

        setIsListening(false); // Update the state
      } else {
        // Start listening
        if (recognitionRef.current) {
          recognitionRef.current.start();
        }

        setIsListening(true); // Update the state
      }
    };

    const handleLanguageChange = (e) => {
      setSelectedLanguage(e.target.value);
    };

    const predefinedModules = [
      {
        component: "Saddle Support",
        category: "S32",
        equal_1: "EN16.08",
        equal_2: "ZICK",
      },
      {
        component: "Leg Support",
        category: "S33",
        equal_1: "EN16.11",
        equal_2: "FELV",
      },
      {
        component: "Bracket Support",
        category: "S34",
        equal_1: "EN16.10",
        equal_2: "FELV",
      },
      {
        component: "Design of tube register heat exchangers",
        category: "AC",
        equal_1: "AC",
        equal_2: "AC",
      },
      {
        component: "Coil heat exchangers",
        category: "COIL",
        equal_1: "COIL",
        equal_2: "COIL",
      },
      {
        component: "Properties of water",
        category: "H2O",
        equal_1: "H2O",
        equal_2: "H2O",
      },
    ];

    // Refresh button
    const handleRefresh = () => {
      // Clear the state (reset the form)
      setSelectedFiles([]);
      setPreviewImages([]);
      setResults([]);
      setLogs([]);

      setTextDescription("");

      // Hide the redirect button, arrow, and text by setting showRedirect to false
      setShowRedirect(false);

      setShowModulesButton(false); // Hide the "Modules you might need" button on refresh
      setShowModulesTable(false); // Reset the table visibility on refresh

      // Clear the file input field using the ref
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Reset the file input value
      }
    };

    const addLog = (message) => {
      setLogs((prevLogs) => [...prevLogs, message]);
    };

    const handleSubmit = async (e) => {
      e.preventDefault();

      // Ensure selectedFiles exists and has length
      // if (!selectedFiles || selectedFiles.length === 0) {
      //   addLog("No files selected.");
      //   return;
      // }

      // const formData = new FormData();
      // selectedFiles.forEach((file) => {
      //   formData.append("files", file); // Changed "photos" to "files" to support various file types
      // });

      // addLog("Uploading files...");

      // Ensure that either textDescription or selectedFiles is present, but not both //N
      if (!textDescription && selectedFiles.length === 0) {
        addLog("Please provide either files or a description.");
        return;
      }

      if (textDescription && selectedFiles.length > 0) {
        addLog(
          "Please provide only one option: either files or a description."
        );
        return;
      }

      const formData = new FormData();
      if (selectedFiles.length > 0) {
        selectedFiles.forEach((file) => {
          formData.append("files", file);
        });
      } else {
        formData.append("description", textDescription); // Send text input if no files are selected
      }

      addLog("Uploading...");

      // Log the API Base URL to verify correct URL
      // console.log("API Base URL:", apiBaseUrl);
      // addLog(`API Base URL: ${apiBaseUrl}`); // Add this to the logs for mobile troubleshooting

      try {
        const response = await axios.post(
          `${apiBaseUrl}/api/modulefinder/upload_images`, // Use the same endpoint for all file types
          formData
        );
        //N

        const data = response.data;
        setResults(data.rows);
        addLog("Files / Description uploaded and analyzed successfully.");
        setShowModulesButton(true); // Show "Modules you might need" button when results are displayed
      } catch (error) {
        console.error("Error uploading files:", error);
        addLog(`Error uploading files: ${error.message}`);
      }
    };

    const Start_Module = async (module) => {
      try {
        addLog(`Attempting to start module: ${module}`);
        const response = await axios.post(
          `${apiBaseUrl}/api/modulefinder/start_module`,
          {
            module: module,
            user_id: uniqueID,
          }
        );

        if (response.status === 200) {
          addLog("Module started successfully.");
        } else {
          addLog("Failed to start module.");
          alert("Failed to start module");
        }
      } catch (error) {
        console.error("Error starting module:", error);
        if (!uniqueID) {
          addLog(
            `Unique ID is empty or undefined: ${uniqueID} ${error.message}`
          );
          setShowRedirect(true); // Set state to show the button, text, and arrow
          // addLog(`Module failed to start`);
          // alert("Unique ID to connect to LV DESIGN ATLAS 2025 is empty");
          // window.location.href = "https://solvematics.com/download-design-atlas";
        } else {
          addLog(`Error starting module: ${uniqueID} ${error.message}`);
        }
        // alert("Error starting module");
      }
    };

    // const handleBackToMenu = () => {
    //   navigate("/"); // Navigate back to the main menu
    // };

    return (
      <div className="container">
        {/* Use the shared Header component */}
        <Header title="LV Module Finder" />

        {/* Form for uploading images */}
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="mb-3">
            <label
              htmlFor="photos"
              className="form-label"
              style={{ display: "flex", alignItems: "center" }}
            >
              {/* Upload Images */}
              <div style={{ flex: 1 }}>
                <h3>Tell us more about your project</h3>
                <ul></ul>
                <p>
                  Please select images or PDF files that depict your
                  construction or describe your design.
                </p>
                <ul>
                  <li>
                    The LV Module Finder will then present you a table of
                    suitable calculation modules.
                  </li>
                  <li>
                    If you&apos;re connected to the{" "}
                    <strong>LV Design Atlas 2025</strong> software, you can
                    launch the modules directly from here.
                  </li>
                </ul>
                <p>
                  Supported file types: <strong>Images (.jpg, .png)</strong> and{" "}
                  <strong>PDF</strong>.
                </p>
                <p>
                  Offers the best experience on{" "}
                  <strong>Microsoft Edge, Firefox, Safari</strong> and
                  <strong> Google Chrome</strong>.
                </p>
              </div>
              <div style={{ marginLeft: "40px", marginRight: "80px" }}>
                <img
                  src="/images/cover_image.png" // Replace with the actual image path
                  alt="Description"
                  style={{
                    width: "300px",
                    height: "205px",
                    objectFit: "cover",
                  }} // Adjust size as necessary
                />
              </div>
            </label>
            <input
              type="file"
              // id="photos"
              // name="photos"
              id="files"
              name="files"
              className="form-control"
              multiple
              // onChange={handleImageChange}
              // refresh button
              accept=".png, .jpg, .jpeg, .pdf, .txt" // Accept PDFs and TXT files as well
              style={{ marginBottom: "20px", marginTop: "40px" }} // This will add space below the file input
              onChange={(event) => {
                const files = event.target.files;
                setSelectedFiles(Array.from(files));
                setPreviewImages(
                  Array.from(files).map((file) => URL.createObjectURL(file))
                );
              }}
              ref={fileInputRef} // Attach the ref to the file input
            />
          </div>

          {/* Bold OR separator */}
          <div
            style={{ textAlign: "left", margin: "20px 0", fontWeight: "bold" }}
          >
            OR
          </div>

          {/* Language Selector */}
          <div className="mb-3">
            <label htmlFor="language" className="form-label">
              Select Language
            </label>
            <select
              id="language"
              value={selectedLanguage}
              onChange={handleLanguageChange}
              className="form-control"
            >
              <option value="en-US">English (US)</option>
              <option value="de-DE">German (Germany)</option>
              {/* You can add more languages here */}
            </select>
          </div>

          {/* Speak Button */}
          <button onClick={handleToggleListening} className="speak-button">
            {isListening ? "Stop Listening" : "Speak"}
          </button>

          {/* N */}
          <div className="mb-3">
            <label htmlFor="description" className="form-label">
              Enter Description
            </label>
            <textarea
              id="description"
              name="description"
              className="form-control"
              value={textDescription}
              onChange={(e) => setTextDescription(e.target.value)}
            ></textarea>
          </div>
          {/* N */}

          {/* Image Previews */}
          <div className="d-flex flex-wrap justify-content-center">
            {/* {previewImages.map((src, index) => ( */}
            {/* {previewImages &&
            previewImages.length > 0 &&
            previewImages.map((src, index) => (
              <div key={index} className="image-preview-container text-center">
                <img
                  src={src}
                  className="image-preview"
                  alt={`Preview ${index + 1}`}
                /> */}
            {previewImages &&
              previewImages.length > 0 &&
              selectedFiles.map((file, index) => {
                // Check file type
                const fileType = file.type;
                const isImage = fileType.startsWith("image/");
                const isPDF = fileType === "application/pdf";
                const isText = fileType === "text/plain";

                return (
                  <div
                    key={index}
                    className="file-preview-container text-center"
                  >
                    {/* For image files, display the preview */}
                    {isImage && (
                      <img
                        src={previewImages[index]} // Image preview
                        // className="image-preview"
                        className="image-preview fixed-size-image"
                        alt={`Preview ${index + 1}`}
                      />
                    )}

                    {/* For PDF files, display a PDF icon */}
                    {isPDF && (
                      <div className="file-icon">
                        <img
                          src="/images/pdf-icon-ai.png" // Replace with your actual path to a PDF icon
                          alt="PDF Icon"
                          // className="file-preview-icon"
                          className="fixed-size-icon" // Class to ensure consistent size
                        />
                      </div>
                    )}

                    {/* For text files, display a TXT icon */}
                    {isText && (
                      <div className="file-icon">
                        <img
                          src="/images/txt-icon-ai.png" // Replace with your actual path to a TXT icon
                          alt="Text Icon"
                          // className="file-preview-icon"
                          className="fixed-size-icon" // Class to ensure consistent size
                        />
                      </div>
                    )}
                    <div>
                      {/* <strong>{`Picture ${index + 1}`}</strong> */}
                      <strong>{`File ${index + 1}`}</strong>{" "}
                      {/* Update label here */}
                    </div>
                  </div>
                );
              })}
          </div>

          {/* Submit button */}
          <div className="center-button">
            <button type="submit" className="btn btn-primary">
              Start finding your modules
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleRefresh}
              style={{ backgroundColor: "grey", borderColor: "grey" }} // Custom color for Refresh
            >
              Refresh
            </button>
          </div>
        </form>

        {showRedirect && ( // Conditionally render this block if showRedirect is true
          <div style={{ textAlign: "center", marginTop: "40px" }}>
            <div
              style={{
                fontWeight: "bold",
                marginBottom: "10px",
                fontSize: "24px",
              }}
            >
              Experience the full capabilities of our AI tool by downloading the
              demo version of LV Design Atlas 2025. Try it out and see how it
              integrates seamlessly.
            </div>
            <div className="arrow-down"></div>
            <button
              className="explore-button"
              style={{ fontSize: "18px" }} // Adjust font size here
              onClick={() =>
                (window.location.href = "https://lv-soft.net/en/trials")
              }
            >
              Download Demo and Explore Full Features
            </button>
          </div>
        )}

        {/* Display results in a table */}
        <div className="result-container mt-5">
          {results.length > 0 && (
            <table className="table table-bordered result-table">
              <thead>
                <tr>
                  <th rowSpan="2">Component</th>
                  <th colSpan="3">Category</th>
                </tr>
                <tr>
                  <th>Module 1</th>
                  <th>Module 2</th>
                  <th>Module 3</th>
                </tr>
              </thead>
              <tbody>
                {results.reduce((acc, row, index) => {
                  if (index === 0 || results[index - 1].file !== row.file) {
                    // if (index === 0 || results[index - 1].picture !== row.picture) {
                    acc.push(
                      <tr key={`file-${index}`} className="file-row">
                        <td colSpan="4" className="left-align">
                          <strong>{row.file}</strong>
                        </td>
                      </tr>
                    );
                  }

                  const isMerged =
                    row.category === row.equal_1 &&
                    row.category === row.equal_2;

                  acc.push(
                    <tr key={`row-${index}`}>
                      <td>{row.component}</td>
                      {isMerged ? (
                        <td
                          colSpan="3"
                          className="module-column"
                          onClick={() => Start_Module(row.category)}
                        >
                          <img
                            src={`/icons/${row.category.replace(".", "_")}.png`}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "/icons/default_ad2000.png";
                            }}
                            alt={`${row.category} Icon`}
                            className="module-icon"
                          />
                          {row.category}
                        </td>
                      ) : (
                        <>
                          <td
                            className="module-column"
                            onClick={() => Start_Module(row.category)}
                          >
                            <img
                              src={`/icons/${row.category.replace(
                                ".",
                                "_"
                              )}.png`}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/icons/default_ad2000.png";
                              }}
                              alt={`${row.category} Icon`}
                              className="module-icon"
                            />
                            {row.category}
                          </td>
                          <td
                            className="module-column"
                            onClick={() => Start_Module(row.equal_1)}
                          >
                            <img
                              src={`/icons/${row.equal_1.replace(
                                ".",
                                "_"
                              )}.png`}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/icons/default_en.png";
                              }}
                              alt={`${row.equal_1} Icon`}
                              className="module-icon"
                            />
                            {row.equal_1}
                          </td>
                          <td
                            className="module-column"
                            onClick={() => Start_Module(row.equal_2)}
                          >
                            <img
                              src={`/icons/${row.equal_2.replace(
                                ".",
                                "_"
                              )}.png`}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/icons/default_asme.png";
                              }}
                              alt={`${row.equal_2.replace(/\./g, "_")} Icon`}
                              className="module-icon"
                            />
                            {row.equal_2}
                          </td>
                        </>
                      )}
                    </tr>
                  );

                  return acc;
                }, [])}
              </tbody>
            </table>
          )}
        </div>

        {/* Button to show the new table */}
        {showModulesButton && (
          <div className="module-button-container">
            <button
              className="btn btn-primary"
              onClick={() => setShowModulesTable(!showModulesTable)}
            >
              Modules you might also need
            </button>
          </div>
        )}

        {/* New table for modules you might need */}
        {showModulesTable && (
          <div className="result-container mt-5">
            <table className="table table-bordered result-table">
              <thead>
                <tr>
                  <th rowSpan="2">Component</th>
                  <th colSpan="3">Category</th>
                </tr>
                <tr>
                  <th>Module 1</th>
                  <th>Module 2</th>
                  <th>Module 3</th>
                </tr>
              </thead>
              <tbody>
                {predefinedModules.map((row, index) => {
                  // Define the local `isMerged` function for this table
                  const isMerged =
                    row.category === row.equal_1 &&
                    row.category === row.equal_2;
                  return (
                    <tr key={index}>
                      <td>{row.component}</td>
                      {isMerged ? (
                        <td
                          colSpan="3"
                          className="module-column"
                          onClick={() => Start_Module(row.category)}
                        >
                          <img
                            src={`/icons/${row.category.replace(".", "_")}.png`}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "/icons/default_ad2000.png";
                            }}
                            alt={`${row.category} Icon`}
                            className="module-icon"
                          />
                          {row.category}
                        </td>
                      ) : (
                        <>
                          <td
                            className="module-column"
                            onClick={() => Start_Module(row.category)}
                          >
                            <img
                              src={`/icons/${row.category.replace(
                                ".",
                                "_"
                              )}.png`}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/icons/default_ad2000.png";
                              }}
                              alt={`${row.category} Icon`}
                              className="module-icon"
                            />
                            {row.category}
                          </td>
                          <td
                            className="module-column"
                            onClick={() => Start_Module(row.equal_1)}
                          >
                            <img
                              src={`/icons/${row.equal_1.replace(
                                ".",
                                "_"
                              )}.png`}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/icons/default_en.png";
                              }}
                              alt={`${row.equal_1} Icon`}
                              className="module-icon"
                            />
                            {row.equal_1}
                          </td>
                          <td
                            className="module-column"
                            onClick={() => Start_Module(row.equal_2)}
                          >
                            <img
                              src={`/icons/${row.equal_2.replace(
                                ".",
                                "_"
                              )}.png`}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/icons/default_asme.png";
                              }}
                              alt={`${row.equal_2} Icon`}
                              className="module-icon"
                            />
                            {row.equal_2}
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}

        {/* Logs Section */}
        <div className="log-section mt-4">
          <h3>Logs:</h3>
          <ul>
            {logs.map((log, index) => (
              <li key={index}>{log}</li>
            ))}
          </ul>
        </div>
      </div>
    );
  }

export default ModuleFinder;
