import React, { useState } from 'react';
import axios from 'axios';

// Use environment variable for API base URL, fallback to localhost for development
const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';


function BoltModule() {
  const [standard, setStandard] = useState('metric'); // Default to metric
  const [boltSize, setBoltSize] = useState('');
  const [boltData, setBoltData] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = () => {
    const endpoint = standard === 'metric' ? 'metric' : 'us';
    axios
      .get(`${apiBaseUrl}/api/bolt_web/${endpoint}`, {
        params: { bolt_size: boltSize },
      })
      .then((response) => {
        setBoltData(response.data);
        setError(null);
      })
      .catch((error) => {
        console.error("Error fetching bolt dimensions:", error); // Log the error
        setError("Error fetching bolt dimensions. Please check your inputs.");
        setBoltData(null);
      });

  };

  return (
    <div className="container">
      <h1>Bolt Dimensions Calculator</h1>

      <div className="form-group">
        <label>Select Standard</label>
        <select value={standard} onChange={e => setStandard(e.target.value)}>
          <option value="metric">Metric</option>
          <option value="us">US</option>
        </select>
      </div>

      <div className="form-group">
        <label>Bolt Size</label>
        <input type="text" value={boltSize} onChange={e => setBoltSize(e.target.value)} placeholder="e.g., M6 or 1/4-20" />
      </div>

      <button onClick={handleSubmit}>Get Bolt Dimensions</button>

      {boltData && (
        <div>
          <h2>Bolt Details</h2>
          <p>Bolt Size: {boltData.bolt_size}</p>
          {standard === 'metric' ? (
            <>
              <p>Major Diameter: {boltData.major_diameter_mm} mm</p>
              <p>Minor Diameter: {boltData.minor_diameter_mm} mm</p>
              <p>Pitch Diameter: {boltData.pitch_diameter_mm} mm</p>
              <p>Bolt Length: {boltData.bolt_length_mm} mm</p>
            </>
          ) : (
            <>
              <p>Major Diameter: {boltData.major_diameter_in} inches</p>
              <p>Minor Diameter: {boltData.minor_diameter_in} inches</p>
              <p>Pitch Diameter: {boltData.pitch_diameter_in} inches</p>
              <p>Bolt Length: {boltData.bolt_length_in} inches</p>
            </>
          )}
        </div>
      )}

      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
}

export default BoltModule;
