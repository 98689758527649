import React, { useState } from 'react';
import axios from 'axios';
import '../assets/Cad.css';
import Header from '../assets/Header.js';

const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

function CadModule() {
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [svgContent, setSvgContent] = useState(null);

  // Handle form submission for test route
  const handleSubmit = () => {
    setError(null);

    axios.get(`${apiBaseUrl}/api/cad/test`, { responseType: 'blob' }) // Expecting SVG as a blob
      .then(response => {
        setMessage("SVG image successfully received.");
        setError(null);

        // Convert blob to an object URL to display in an <img> or <object> tag
        const url = URL.createObjectURL(response.data);
        setSvgContent(url);
      })
      .catch(error => {
        console.error("Error fetching SVG:", error);
        setError("Failed to fetch SVG. Please try again.");
      });
  };

  return (
    <div className="container">
      <Header title="LV CAD Module" />

      {/* Button to trigger the test API call */}
      <button onClick={handleSubmit} className="btn btn-primary">Test CAD Module</button>

      {/* Display error message if any */}
      {error && <div className="alert alert-danger" role="alert">{error}</div>}

      {/* Display response message */}
      {message && <div className="alert alert-success" role="alert">{message}</div>}

      {/* Display SVG image if available */}
      <div id="results" className="mt-4">
        {svgContent ? (
          <object data={svgContent} type="image/svg+xml" className="svg-display">
            Your browser does not support SVG.
          </object>
        ) : (
          <p>No response received yet. Click &quot;Test CAD Module&quot; to send a request.</p>
        )}
      </div>
    </div>
  );
}

export default CadModule;




