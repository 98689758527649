import React from 'react';
import { List, ListItem, ListItemText, Typography, Box } from '@mui/material';

function SidebarMenu({ modules, selectedModule, onSelectModule }) {
  return (
    <Box sx={{ width: 250 }}>
      <Typography variant="h6" sx={{ padding: 2 }}>
        Modules
      </Typography>
      <List>
        {modules.map((module, index) => (
          <ListItem
            key={index}
            button
            selected={selectedModule === module}
            onClick={() => onSelectModule(module)}
          >
            <ListItemText primary={module.replace('_', ' ').toUpperCase()} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default SidebarMenu;
