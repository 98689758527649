import React, { useState } from 'react';
import { Box, TextField, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

function EquationEditor({ equations, onSave, onClose }) {
  const [editedEquations, setEditedEquations] = useState([...equations]);

  // Handle equation change
  const handleEquationChange = (index, value) => {
    const updatedEquations = [...editedEquations];
    updatedEquations[index] = value;
    setEditedEquations(updatedEquations);
  };

  // Add a new equation
  const handleAddEquation = () => {
    setEditedEquations([...editedEquations, '']);
  };

  // Remove an equation by index
  const handleRemoveEquation = (index) => {
    const updatedEquations = editedEquations.filter((_, idx) => idx !== index);
    setEditedEquations(updatedEquations);
  };

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit Equations</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2}>
          {editedEquations.map((eq, idx) => (
            <Box key={idx} display="flex" alignItems="center" gap={1}>
              <TextField
                fullWidth
                label={`Equation ${idx + 1}`}
                value={eq}
                onChange={(e) => handleEquationChange(idx, e.target.value)}
              />
              <IconButton onClick={() => handleRemoveEquation(idx)} color="secondary">
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
          <Button startIcon={<AddIcon />} onClick={handleAddEquation} variant="contained" color="primary">
            Add Equation
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onSave(editedEquations)} color="primary" variant="contained">
          Save
        </Button>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EquationEditor;
