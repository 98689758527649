import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../assets/Oel.css'; // Assuming you have a CSS file for Oel module styling
import Header from '../assets/Header.js';  // Import the Header component

const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

function OelModule() {
  const [temperature, setTemperature] = useState('');
  const [oilName, setOilName] = useState('');
  const [oils, setOils] = useState([]);
  const [interpolatedData, setInterpolatedData] = useState(null);
  const [error, setError] = useState(null);
  const [requiresViscosityIndex, setRequiresViscosityIndex] = useState(false);
  const [viscosityIndex, setViscosityIndex] = useState('');
  const [viscosityIndices, setViscosityIndices] = useState([]);

  // Fetch the available oils on component mount
  useEffect(() => {
    axios.get(`${apiBaseUrl}/api/oel/oils`)
      .then(response => {
        setOils(response.data);
      })
      .catch(error => {
        console.error('Error fetching oils:', error);
        setError('Failed to load available oils.');
      });
  }, []);

  // Handle oil selection and manage viscosity index requirement
  const handleOilChange = (e) => {
    const selectedOil = oils.find(oil => oil.file_name === e.target.value);
    setOilName(selectedOil.file_name);
    setRequiresViscosityIndex(selectedOil.requires_viscosity_index);
    setViscosityIndices(selectedOil.viscosity_indices || []);
    setViscosityIndex(''); // Reset viscosity index when changing oil
  };

  // Handle form submission
  const handleSubmit = () => {
    if (!temperature || !oilName || (requiresViscosityIndex && !viscosityIndex)) {
      setError('Please select all required fields.');
      return;
    }

    // Determine which API route to use based on the presence of viscosity index
    const apiRoute = requiresViscosityIndex
      ? `${apiBaseUrl}/api/oel/calculate_with_viscosity_index`
      : `${apiBaseUrl}/api/oel/get_properties`;

    // Reset error and send request to the appropriate API
    setError(null);
    axios.post(apiRoute, {
      oil_name: oilName,
      temperature: temperature,
      viscosity_index: viscosityIndex || null  // Include viscosity index only if it's required
    })
    .then(response => {
      console.log('Response data:', response.data);
      setInterpolatedData(response.data);
      setError(null);
    })
    .catch(error => {
      console.error('Error fetching properties:', error);
      setError('Failed to fetch interpolated data. Please try again.');
    });
  };

  // Create an array of properties with name, unit, and value
  const formattedData = interpolatedData ? [
    { name: 'Temperature', unit: '°C', value: interpolatedData.temperature },
    { name: 'Viscosity Index', unit: '-', value: interpolatedData.viscosity_index },
    { name: 'Density at 15°C', unit: 'g/cm³', value: interpolatedData.rho_n },
    { name: 'Interpolated Density', unit: 'g/cm³', value: interpolatedData.interpolated_density },
    { name: 'Interpolated cp', unit: 'kJ/kg·K', value: interpolatedData.interpolated_cp },
    { name: 'Interpolated Lambda', unit: 'W/m·K', value: interpolatedData.interpolated_lambda },
    { name: 'Interpolated Kinematic Viscosity', unit: 'mm²/s', value: interpolatedData.interpolated_kinematic_viscosity },
    { name: 'Interpolated Dynamic Viscosity', unit: 'Pa·s', value: interpolatedData.interpolated_dynamic_viscosity },
    { name: 'Density Low', unit: 'g/cm³', value: interpolatedData.density_low },
    { name: 'Density High', unit: 'g/cm³', value: interpolatedData.density_high },
    { name: 'Dynamic Viscosity', unit: 'Pa·s', value: interpolatedData.Dynamic_viscosity },
    { name: 'Density', unit: 'g/cm³', value: interpolatedData.Density },
    { name: 'Specific Heat Capacity (cp)', unit: 'kJ/kg·K', value: interpolatedData.cp },
    { name: 'Thermal Conductivity (Lambda)', unit: 'W/m·K', value: interpolatedData.Lambda },
    { name: 'Kinematic Viscosity', unit: 'mm²/s', value: interpolatedData.Kinematic_viscosity }
  ].filter(item => item.value !== undefined) : [];

  return (
    <div className="container">
      <Header title="Oil Properties Module" />

      <div className="form-group">
        <label>Select Oil:</label>
        <select
          className="form-control"
          value={oilName}
          onChange={handleOilChange}
        >
          <option value="">-- Select an oil --</option>
          {oils.map((oil, index) => (
            <option key={index} value={oil.file_name}>{oil.display_name}</option>
          ))}
        </select>
      </div>

      {requiresViscosityIndex && (
        <div className="form-group">
          <label>Select Viscosity Index:</label>
          <select
            className="form-control"
            value={viscosityIndex}
            onChange={e => setViscosityIndex(e.target.value)}
          >
            <option value="">-- Select a viscosity index --</option>
            {viscosityIndices.map((index, idx) => (
              <option key={idx} value={index}>{index}</option>
            ))}
          </select>
        </div>
      )}

      <div className="form-group">
        <label>Temperature (°C):</label>
        <input
          type="number"
          className="form-control"
          value={temperature}
          onChange={e => setTemperature(e.target.value)}
        />
      </div>

      <button onClick={handleSubmit} className="btn btn-primary">Get Interpolated Data</button>

      {error && <div className="alert alert-danger" role="alert">{error}</div>}

      <div id="results" className="mt-4">
        {formattedData.length > 0 ? (
          <table className="table table-bordered mt-4">
            <thead>
              <tr>
                <th>Name</th>
                <th>Unit</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {formattedData.map((property, index) => (
                <tr key={index}>
                  <td>{property.name}</td>
                  <td>{property.unit}</td>
                  <td>{typeof property.value === 'number' ? property.value.toFixed(4) : property.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No data available. Please fill out the form and submit.</p>
        )}
      </div>
    </div>
  );
}

export default OelModule;
