// B1Menu.js
import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import './B1Menu.css';  // Stelle sicher, dass die CSS-Datei eingebunden ist

function B1Menu({ onCalculate }) {
  return (
    <Navbar bg="light" expand="lg" className="custom-navbar">
      <div className="d-flex w-100 justify-content-between">
        <div className="ml-5"></div> {/* Empty div to create 200px space */}
        <Nav className="mr-auto custom-nav">
          <NavDropdown title="File" id="file-dropdown">
            <NavDropdown.Item href="#new">New Calculation</NavDropdown.Item>
            <NavDropdown.Item href="#save">Save Calculation</NavDropdown.Item>
            <NavDropdown.Item href="#load">Load Calculation</NavDropdown.Item>
            <NavDropdown.Item href="#pdf">Export to PDF</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#exit">Exit</NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title="Calculate" id="calculate-dropdown">
            <NavDropdown.Item onClick={onCalculate}>Calculate Module</NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title="Edit" id="edit-dropdown">
            <NavDropdown.Item href="#reset">Reset Form</NavDropdown.Item>
            <NavDropdown.Item href="#undo">Undo</NavDropdown.Item>
            <NavDropdown.Item href="#redo">Redo</NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title="View" id="view-dropdown">
            <NavDropdown.Item href="#units">Toggle Units</NavDropdown.Item>
            <NavDropdown.Item href="#history">Calculation History</NavDropdown.Item>
            <NavDropdown.Item href="#logs">Show/Hide Logs</NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title="Tools" id="tools-dropdown">
            <NavDropdown.Item href="#materials">Material Database</NavDropdown.Item>
            <NavDropdown.Item href="#converter">Unit Converter</NavDropdown.Item>
            <NavDropdown.Item href="#geometry">Geometry Helper</NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title="Help" id="help-dropdown">
            <NavDropdown.Item href="#guide">User Guide</NavDropdown.Item>
            <NavDropdown.Item href="#about">About</NavDropdown.Item>
            <NavDropdown.Item href="#support">Contact Support</NavDropdown.Item>
          </NavDropdown>
        </Nav>
        <Navbar.Brand className="navbar-brand-right">Design Atlas 11 Web Edition : LV-SOFT.NET</Navbar.Brand>
      </div>
    </Navbar>
  );
}

export default B1Menu;
