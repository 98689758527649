import React, { useState } from 'react';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
import '../assets/B1.css';
import SidebarMenu from '../components/SidebarMenu.js'; // SidebarMenu importieren
import B1Menu from '../components/B1Menu.js';  // Adjust the path according to your folder structure
import '../assets/B1Module.css';  // Zusätzliche CSS für Layout

const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

function B1Module() {
  const [vesselType, setVesselType] = useState('cylinder');
  const [Da, setDa] = useState('');
  const [T, setT] = useState(''); // New field for temperature
  const [p, setP] = useState('');
  const [pPrime, setPPrime] = useState(''); // New field for test pressure
  const [K, setK] = useState('');
  const [KPrime, setKPrime] = useState(''); // New field for material test constant
  const [S, setS] = useState('');
  const [SPrime, setSPrime] = useState(''); // New field for test safety factor
  const [v, setV] = useState('');
  const [c1, setC1] = useState('');
  const [c2, setC2] = useState('');
  const [rho, setRho] = useState(''); // New field for density
  const [wallThickness, setWallThickness] = useState(null);
  const [error, setError] = useState(null);
  const [logs, setLogs] = useState([]);
  // const navigate = useNavigate();

  const addLog = (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
  };

  const handleSubmit = () => {
    if (!Da || !p || !K || !S || !v || !T || !pPrime || !KPrime || !SPrime || !rho) {
      setError("Please fill in all required fields.");
      addLog("Form submission failed due to missing fields.");
      return;
    }

    const data = {
      vessel_type: vesselType,
      Da: parseFloat(Da),
      T: parseFloat(T),
      p: parseFloat(p),
      pPrime: parseFloat(pPrime),
      K: parseFloat(K),
      KPrime: parseFloat(KPrime),
      S: parseFloat(S),
      SPrime: parseFloat(SPrime),
      v: parseFloat(v),
      c1: parseFloat(c1) || 0,
      c2: parseFloat(c2) || 0,
      rho: parseFloat(rho)
    };

    addLog("Sending calculation data to the server...");

    axios.post(`${apiBaseUrl}/api/b1/calculate_wall_thickness`, data)
      .then(response => {
        setWallThickness(response.data.wall_thickness);
        setError(null);
        addLog("Calculation received successfully.");
      })
      .catch(error => {
        setError("An error occurred during the calculation.");
        addLog(`Calculation error: ${error.message}`);
      });
  };

  return (
    <div className="b1module-container">
      <SidebarMenu /> {/* Seitliches Menü */}
      <div className="content">
        <B1Menu onCalculate={handleSubmit} /> {/* Top Navbar */}
        <div className="container">
          {/* <div className="header">
        <h1>B1 Pressure Vessel Wall Thickness Calculator</h1>
        <button onClick={handleBackToMenu} className="btn btn-secondary mt-3">Back to Main Menu</button>
        <div className="logo-container">
          <a href="https://lv-soft.net" target="_blank" rel="noopener noreferrer">
            <img src={logo} alt="Logo" className="logo" />
          </a>
          <div className="logo-text">
            More modules available at <a href="https://lv-soft.net" target="_blank" rel="noopener noreferrer">lv-soft.net</a>.
          </div>
        </div>
      </div> */}

          {/* Form for calculation */}
          <div className="form-section mt-4">
            <h3>Design Inputs</h3>
            <div className="form-group">
              <label htmlFor="vesselType">Vessel Type</label>
              <select
                id="vesselType"
                className="form-control"
                value={vesselType}
                onChange={(e) => setVesselType(e.target.value)}
              >
                <option value="cylinder">Cylinder</option>
                <option value="sphere">Sphere</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="Da">Outer Diameter (Da) [mm]</label>
              <input
                type="number"
                id="Da"
                className="form-control"
                value={Da}
                onChange={(e) => setDa(e.target.value)}
                placeholder="e.g., 500"
              />
            </div>

            <div className="form-group">
              <label htmlFor="T">Calculation Temperature (T) [°C]</label>
              <input
                type="number"
                id="T"
                className="form-control"
                value={T}
                onChange={(e) => setT(e.target.value)}
                placeholder="e.g., 150"
              />
            </div>

            <div className="form-group">
              <label htmlFor="p">Internal Pressure (p) [bar]</label>
              <input
                type="number"
                id="p"
                className="form-control"
                value={p}
                onChange={(e) => setP(e.target.value)}
                placeholder="e.g., 10"
              />
            </div>

            <div className="form-group">
              <label htmlFor="pPrime">Test Pressure (p&apos;) [bar]</label>
              <input
                type="number"
                id="pPrime"
                className="form-control"
                value={pPrime}
                onChange={(e) => setPPrime(e.target.value)}
                placeholder="e.g., 15"
              />
            </div>

            <h3>Material Properties</h3>
            <div className="form-group">
              <label htmlFor="K">Material Constant (K) [N/mm²]</label>
              <input
                type="number"
                id="K"
                className="form-control"
                value={K}
                onChange={(e) => setK(e.target.value)}
                placeholder="e.g., 1.2"
              />
            </div>

            <div className="form-group">
              <label htmlFor="KPrime">
                Material Test Constant (K&apos;) [N/mm²]
              </label>
              <input
                type="number"
                id="KPrime"
                className="form-control"
                value={KPrime}
                onChange={(e) => setKPrime(e.target.value)}
                placeholder="e.g., 1.3"
              />
            </div>

            <div className="form-group">
              <label htmlFor="S">Safety Factor (S)</label>
              <input
                type="number"
                id="S"
                className="form-control"
                value={S}
                onChange={(e) => setS(e.target.value)}
                placeholder="e.g., 2.0"
              />
            </div>

            <div className="form-group">
              <label htmlFor="SPrime">Test Safety Factor (S&apos;)</label>
              <input
                type="number"
                id="SPrime"
                className="form-control"
                value={SPrime}
                onChange={(e) => setSPrime(e.target.value)}
                placeholder="e.g., 2.5"
              />
            </div>

            <div className="form-group">
              <label htmlFor="v">Weld Efficiency Factor (v)</label>
              <input
                type="number"
                id="v"
                className="form-control"
                value={v}
                onChange={(e) => setV(e.target.value)}
                placeholder="e.g., 0.85"
              />
            </div>

            <div className="form-group">
              <label htmlFor="c1">Additional Thickness c1 [mm]</label>
              <input
                type="number"
                id="c1"
                className="form-control"
                value={c1}
                onChange={(e) => setC1(e.target.value)}
                placeholder="e.g., 5"
              />
            </div>

            <div className="form-group">
              <label htmlFor="c2">Additional Thickness c2 [mm]</label>
              <input
                type="number"
                id="c2"
                className="form-control"
                value={c2}
                onChange={(e) => setC2(e.target.value)}
                placeholder="e.g., 3"
              />
            </div>

            <div className="form-group">
              <label htmlFor="rho">Density (ρ) [t/m³]</label>
              <input
                type="number"
                id="rho"
                className="form-control"
                value={rho}
                onChange={(e) => setRho(e.target.value)}
                placeholder="e.g., 7.85"
              />
            </div>

            <button onClick={handleSubmit} className="btn btn-primary mt-3">
              Calculate Wall Thickness
            </button>

            {wallThickness && (
              <div className="result mt-4">
                <h4>Calculated Wall Thickness: {wallThickness} mm</h4>
              </div>
            )}

            {error && (
              <div className="error mt-4">
                <p style={{ color: "red" }}>{error}</p>
              </div>
            )}
          </div>

          <div className="log-section mt-5">
            <h3>Logs:</h3>
            <ul>
              {logs.map((log, index) => (
                <li key={index}>{log}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default B1Module;
