import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid, Box, Typography, TextField, Button, Accordion, AccordionSummary, AccordionDetails, Snackbar, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IterMenu from '../components/IterMenu.js';
import SidebarMenu from '../components/SidebarMenu.js';
import jsPDF from 'jspdf';
import { useNavigate } from 'react-router-dom';
import EquationEditor from '../components/EquationEditor.js'; // Import the EquationEditor component

const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

function IterModule() {
  const [modules] = useState(['module_1', 'module_2', 'module_3', 'TestModule']);
  const [selectedModule, setSelectedModule] = useState('module_1');
  const [equations, setEquations] = useState([]);
  const [variables, setVariables] = useState({});
  const [variableValues, setVariableValues] = useState({});
  // const [solution, setSolution] = useState({});
  const [moduleInfo, setModuleInfo] = useState({});
  const [error, setError] = useState(null);
  const [logs, setLogs] = useState([]);
  const [modifiedEquation, setModifiedEquation] = useState("");
  const [showLogs, setShowLogs] = useState(false);
  const [showEquationEditor, setShowEquationEditor] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [showAddEquation, setShowAddEquation] = useState(false);
  // const [contextMenu, setContextMenu] = useState(null);
  // const [selectedVariable, setSelectedVariable] = useState(null);
  

  const navigate = useNavigate();

  // Fetch equations, variables, and module metadata when the selected module changes
  useEffect(() => {
    const fetchEquationsAndState = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/api/iter/get-equations`, {
          params: { module: selectedModule }
        });
        const { equations, variables, module_info } = response.data;

        setEquations(equations);
        setVariables(variables);
        setModuleInfo(module_info);
        setError(null);

        const stateResponse = await axios.get(`${apiBaseUrl}/api/iter/get-module-state`, {
          params: { module_name: selectedModule }
        });

        if (stateResponse.data) {
          const restoredValues = {};
          Object.entries(stateResponse.data).forEach(([variable, valueData]) => {
            restoredValues[variable] = {
              value: valueData.value,
              calculated: valueData.calculated || false,
            };
          });
          setVariableValues(restoredValues);
        }
      } catch (error) {
        setError("Error fetching equations or module state from the server.");
        setSnackbarMessage("Error fetching data");
        setSnackbarOpen(true);
      }
    };

    fetchEquationsAndState();
  }, [selectedModule]);

  const handleSnackbarClose = () => setSnackbarOpen(false);

  // Function to toggle the logs visibility
  const handleToggleLogs = () => {
    setShowLogs(prevShowLogs => !prevShowLogs);
  };

  // Function to toggle "Add New Equation"
  const handleToggleAddEquation = () => {
    setShowAddEquation(prevShowAddEquation => !prevShowAddEquation);
  };

  const handleNewCalculation = () => {
    setVariableValues({});
    // setSolution({});
    setError(null);
    setLogs([]);
  };

  const handleSaveCalculation = () => {
    const calculationData = {
      equations,
      variables,
      variableValues,
      selectedModule,
      moduleInfo,
    };
    localStorage.setItem('savedCalculation', JSON.stringify(calculationData));
    setSnackbarMessage("Calculation saved successfully!");
    setSnackbarOpen(true);
  };

  const handleLoadCalculation = () => {
    const savedData = localStorage.getItem('savedCalculation');
    if (savedData) {
      const calculationData = JSON.parse(savedData);
      setEquations(calculationData.equations);
      setVariables(calculationData.variables);
      setVariableValues(calculationData.variableValues);
      setSelectedModule(calculationData.selectedModule);
      setModuleInfo(calculationData.moduleInfo);
      setError(null);
      setLogs([]);
      setSnackbarMessage("Calculation loaded successfully!");
      setSnackbarOpen(true);
    } else {
      setSnackbarMessage("No saved calculation found.");
      setSnackbarOpen(true);
    }
  };

  const handleExportToPDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(16);
    doc.text('Calculation Report', 20, 20);
    doc.setFontSize(12);
    let yPosition = 30;
  
    doc.text(`Module: ${moduleInfo.module_name}`, 20, yPosition);
    yPosition += 10;
  
    doc.text('Variables:', 20, yPosition);
    yPosition += 10;
  
    Object.entries(variableValues).forEach(([variable, data]) => {
      const value = data.value !== undefined ? data.value : 'N/A';
      const unit = variables[variable]?.unit || '';
      doc.text(`${variables[variable]?.name || variable}: ${value} ${unit}`, 20, yPosition);
      yPosition += 10;
    });
  
    doc.text('Equations:', 20, yPosition);
    yPosition += 10;
  
    equations.forEach((equation, index) => {
      doc.text(`${index + 1}. ${equation}`, 20, yPosition);
      yPosition += 10;
    });
  
    doc.save('calculation_report.pdf');
  };

  const handleExit = () => {
    navigate('/');
  };

  const handleResetForm = () => {
    setVariableValues({});
    // setSolution({});
    setError(null);
    setLogs([]);
  };

  const handleModuleSelect = (module) => {
    setSelectedModule(module);
    // setSolution({});
    setVariableValues({});
  };

  const handleEquationChange = (index, newEquation) => {
    const updatedEquations = [...equations];
    updatedEquations[index] = newEquation;
    setEquations(updatedEquations);

    const newVariables = newEquation.match(/[a-zA-Z_][a-zA-Z0-9_]*/g); 
    if (newVariables) {
      const updatedVariables = { ...variables };
      newVariables.forEach((variable) => {
        if (!updatedVariables[variable]) {
          updatedVariables[variable] = { unit: "", name: `Variable ${variable}`, input_type: "standard" };
        }
      });
      setVariables(updatedVariables);
    }
  };

  const handleAddNewEquation = () => {
    if (modifiedEquation) {
      setEquations([...equations, modifiedEquation]);
      handleEquationChange(equations.length, modifiedEquation);
      setModifiedEquation("");
    }
  };

  const handleSaveEquations = (newEquations) => {
    setEquations(newEquations);
    setShowEquationEditor(false); // Close the modal after saving
    setSnackbarMessage("Equations saved successfully!");
    setSnackbarOpen(true);
  };

  const handleVariableChange = (varName, value) => {
    // setSolution({});  // Clear previously solved variables
    setVariableValues((prevValues) => ({
      ...prevValues,
      [varName]: { value: parseFloat(value), calculated: false }  // Mark as user input (not calculated)
    }));
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSolveAll(); // Trigger solve when Enter is pressed
    }
  };

  const handleBlur = () => {
    handleSolveAll(); // Trigger solve when input loses focus
  };

  const handleSolveAll = async () => {
    addLog("Solving all equations...");

    // setSolution({});
    setVariableValues({});

    try {
      const response = await axios.post(`${apiBaseUrl}/api/iter/solve-all-equations`, {
        module_name: selectedModule,
        equations,
        known_values: Object.fromEntries(Object.entries(variableValues).map(([key, obj]) => [key, obj.value])),
        variables,
      });
      // setSolution(response.data);
      setVariableValues(response.data);
      setError(null);
      addLog("All equations solved successfully.");
      setSnackbarMessage("Equations solved successfully!");
      setSnackbarOpen(true);
    } catch (error) {
      setError("An error occurred during the calculation.");
      addLog(`Error: ${error.message}`);
      setSnackbarMessage("Error solving equations");
      setSnackbarOpen(true);
    }
  };

  const addLog = (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
  };

  // Group variables by 'group' and sort them by 'order'
  const groupedVariables = Object.entries(variables).reduce((acc, [key, value]) => {
    const group = value.group || 'Ungrouped';
    acc[group] = acc[group] || [];
    acc[group].push({ variable: key, ...value });
    return acc;
  }, {});

  const sortedGroups = Object.entries(groupedVariables).map(([group, vars]) => ({
    group,
    variables: vars.sort((a, b) => a.order - b.order)
  }));

  return (
    <Box display="flex">
      {/* Sidebar Menu */}
      <SidebarMenu 
        modules={modules} 
        selectedModule={selectedModule} 
        onSelectModule={handleModuleSelect} 
      />

      <Grid container spacing={3}>
        {/* Iter Menu */}
        <Grid item xs={12}>
          <IterMenu
            onCalculate={handleSolveAll} 
            onNewCalculation={handleNewCalculation}
            onSaveCalculation={handleSaveCalculation}
            onLoadCalculation={handleLoadCalculation}
            onExportToPDF={handleExportToPDF}
            onExit={handleExit}
            onResetForm={handleResetForm}
            onToggleLogs={handleToggleLogs} // Add this to control log visibility
            onToggleAddEquation={handleToggleAddEquation}  // Pass the toggle function
            showAddEquation={showAddEquation}  // Pass the current state to control the menu label
            onOpenEquationEditor={() => setShowEquationEditor(true)} // Open Equation Editor
          />
        </Grid>

        {/* Module Info */}
        {moduleInfo && (
          <Grid item xs={12}>
            <Typography variant="h6">{moduleInfo.module_name}</Typography>
            <Typography>Description: {moduleInfo.description}</Typography>
            <Typography>Version: {moduleInfo.version}</Typography>
          </Grid>
        )}

        {/* Equations */}
        {equations.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="h6">Equations</Typography>
            {equations.map((equation, index) => (
              <TextField
                key={index}
                fullWidth
                margin="normal"
                label={`Equation ${index + 1}`}
                value={equation}
                onChange={(e) => handleEquationChange(index, e.target.value)}
              />
            ))}
          </Grid>
        )}

        {/* Grouped Variables */}
        {sortedGroups.map(({ group, variables }, index) => (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">{group}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box display="flex" flexWrap="wrap" gap={2}>
                {variables.map((variableData) => (
                  <TextField
                    key={variableData.variable}
                    label={variableData.name}
                    value={variableValues[variableData.variable]?.value || ''}
                    onChange={(e) => handleVariableChange(variableData.variable, e.target.value)}
                    onKeyPress={handleKeyPress}  // Solve when Enter is pressed
                    onBlur={handleBlur}  // Solve when the field loses focus
                    InputProps={{
                      endAdornment: <span>{variableData.unit}</span>,
                      readOnly: variableData.input_type === 'readonly',
                      style: { backgroundColor: variableValues[variableData.variable]?.calculated ? 'lightblue' : 'white' }
                    }}
                    style={{ width: '100%' }}
                  />
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}

        {/* Error Handling */}
        {error && (
          <Grid item xs={12}>
            <Typography color="error">{error}</Typography>
          </Grid>
        )}

        {/* Logs */}
        {showLogs && (
          <Grid item xs={12}>
            <Typography variant="h6">Logs</Typography>
            <ul>
              {logs.map((log, index) => (
                <li key={index}>{log}</li>
              ))}
            </ul>
          </Grid>
        )}

        {/* Add New Equation */}
        {showAddEquation && (
          <Grid item xs={12}>
            <Typography variant="h6">Add New Equation</Typography>
            <TextField
              fullWidth
              value={modifiedEquation}
              onChange={(e) => setModifiedEquation(e.target.value)}
              placeholder="Enter new equation"
              margin="normal"
            />
            <Button variant="outlined" color="secondary" onClick={handleAddNewEquation}>
              Add Equation
            </Button>
          </Grid>
        )}

        {/* Equation Editor Dialog */}
        <Dialog open={showEquationEditor} onClose={() => setShowEquationEditor(false)} maxWidth="md" fullWidth>
          <DialogTitle>Equation Editor</DialogTitle>
          <DialogContent>
            <EquationEditor equations={equations} onSave={handleSaveEquations} onClose={() => setShowEquationEditor(false)} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowEquationEditor(false)} color="primary">Close</Button>
          </DialogActions>
        </Dialog>

      </Grid>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </Box>
  );
}

export default IterModule;
