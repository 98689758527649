import React, { useState } from "react";
import "../assets/B3.css"; 
import logo from "../assets/logo.png";
import { useNavigate } from "react-router-dom";

// Use environment variable for API base URL, fallback to localhost for development
const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

function B3Module() {
  const [Da, setDa] = useState("");
  const [se, setSe] = useState("");
  const [p, setP] = useState("");
  const [di, setdi] = useState("");
  const [T, setT] = useState("");
  const [K, setK] = useState("");
  const [S, setS] = useState("");
  const [c1, setc1] = useState("");
  const [c2, setc2] = useState("");
  const [v, setv] = useState("");
  const [beta, setbeta] = useState("");

  const [result, setResult] = useState(null);
  const navigate = useNavigate(); // Initialisiere die navigate-Funktion

  // Funktion zum Zurückkehren zum Hauptmenü
  const handleBackToMenu = () => {
    navigate("/"); // Passe den Pfad entsprechend deinem Routing an
  };

  const handleCalculate = async (e) => {
    e.preventDefault();
    const response = await fetch(
      `${apiBaseUrl}/api/b3_web/calculate_wall_thickness`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          Da: Number(Da),
          se: Number(se),
          p: Number(p),
          di: Number(di),
          T: Number(T),
          K: Number(K),
          S: Number(S),
          c1: Number(c1),
          c2: Number(c2),
          v: Number(v),
          beta: Number(beta),
        }),
      }
    );
    const data = await response.json();
    setResult(data.knuckle_wall_thickness);
  };

  return (
    // <div>
    //   <h1>B3 Calculation</h1>
    <div className="container">
      {/* Header mit Logo und Titel */}
      <div className="header">
        <h1>B3 End types Knuckle Wall Thickness Calculator</h1>
        <button onClick={handleBackToMenu} className="btn btn-secondary mt-3">
          Back to Main Menu
        </button>
        <div className="logo-container">
          <a
            href="https://lv-soft.net"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={logo} alt="Logo" className="logo" />
          </a>
          <div className="logo-text">
            Weitere Module findest du auf{" "}
            <a
              href="https://lv-soft.net"
              target="_blank"
              rel="noopener noreferrer"
            >
              lv-soft.net
            </a>
            .
          </div>
        </div>
      </div>

      {/* Formular für die Berechnung */}
      <div className="form-section mt-4">
        <form onSubmit={handleCalculate}>
          <label htmlFor="Da">Outer Diameter (Da) [mm]</label>
          <input
            type="number"
            // placeholder="Da"
            value={Da}
            onChange={(e) => setDa(e.target.value)}
            placeholder="z.B. 850"
          />
          <label htmlFor="se">Final wall thickness (se) [mm]</label>
          <input
            type="number"
            placeholder="z.B. 10"
            value={se}
            onChange={(e) => setSe(e.target.value)}
          />
          <label htmlFor="p">Internal Pressure (p) [bar]</label>
          <input
            type="number"
            placeholder="z.B. 4.2"
            value={p}
            onChange={(e) => setP(e.target.value)}
          />
          <label htmlFor="di">
            Maximum nozzle diameter outside 0.6*Da (di) [mm]
          </label>
          <input
            type="number"
            placeholder="z.B. 273"
            value={di}
            onChange={(e) => setdi(e.target.value)}
          />
          <label htmlFor="T">Final wall thickness (T) [degC]</label>
          <input
            type="number"
            placeholder="z.B. 300"
            value={T}
            onChange={(e) => setT(e.target.value)}
          />
          <label htmlFor="K">Nominal design strength [N/mm²]</label>
          <input
            type="number"
            placeholder="z.B. 175"
            value={K}
            onChange={(e) => setK(e.target.value)}
          />
          <label htmlFor="S">Safety Factor [S]</label>
          <input
            type="number"
            placeholder="z.B. 1.5"
            value={S}
            onChange={(e) => setS(e.target.value)}
          />
          <label htmlFor="c1">Additional Thickness c1 (Optional) [mm]</label>
          <input
            type="number"
            placeholder="z.B. 0"
            value={c1}
            onChange={(e) => setc1(e.target.value)}
          />
          <label htmlFor="c2">Additional Thickness c2 (Optional) [mm]</label>
          <input
            type="number"
            placeholder="z.B. 0"
            value={c2}
            onChange={(e) => setc2(e.target.value)}
          />
          <label htmlFor="v">Weld Efficiency Factor (v)</label>
          <input
            type="number"
            placeholder="z.B. 1"
            value={v}
            onChange={(e) => setv(e.target.value)}
          />
          <label htmlFor="beta">Shape Factor (beta)</label>
          <input
            type="number"
            placeholder="z.B. (0-10)"
            value={beta}
            onChange={(e) => setbeta(e.target.value)}
          />
          <button type="submit">Calculate Knuckle Wall Thickness</button>
        </form>
        {result && <p> Knuckle Wall Thickness: {result} mm</p>}
      </div>
    </div>
  );
}

export default B3Module;
